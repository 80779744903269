import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta name="theme-color" content="#045483" />
        <meta name="yandex-verification" content="16d98dc18479bff1" />
        <meta name="yandex-verification" content="74f6f6a5b329b281" />
        <meta
          name="description"
          content="En defensa de tus derechos laborales"
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container hero-text">
          <h1>Asesoría laboral gratuita</h1>
          <p>
            Sabemos lo valioso que es tu trabajo, por lo tanto nos esforzamos
            para que sus frutos se mantengan en tus manos; ¡Llámanos para
            agendar una cita y poder resolver tu caso ahora!
          </p>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Nuestros servicios</h2>
          </div>
          {markdown.map(edge => (
            <div
              key={edge.node.frontmatter.path}
              className="col-12 col-md-4 mb-1 service-wrapper"
            >
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.frontmatter.path}>
                      {edge.node.frontmatter.title}
                    </Link>
                  </h2>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/services">
              Ver todos los servicios
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Conócenos</h2>
          </div>
          {json.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
              <div className="feature">
                {edge.node.image && (
                  <div className="feature-image">
                    <i className="material-icons feature-icon">
                      {edge.node.image}
                    </i>
                  </div>
                )}
                <h2 className="feature-title">{edge.node.title}</h2>
                <div className="feature-content">{edge.node.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Oficinas</h2>
          </div>
          <h2 className="text-left">CDMX</h2>
          <iframe
            title="CL Abogados Laborales"
            frameBorder="0"
            style={{ width: '98%', height: '400px', border: '0' }}
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ3fyDwnT_0YURNbinErQces0&key=AIzaSyCFjEGZYmeX0OtTqAwuYtK4TRpqMmqvtVg"
          />
          <br/>
          <br/>
          <h2 className="text-left">Estado de México</h2>
          <iframe
            title="CL Abogados Laborales (Despacho 12-09, Plaza Esmeralda)"
            frameBorder="0"
            style={{ width: '98%', height: '400px', border: '0' }}
            src="https://www.google.com/maps/embed/v1/place?q=Tlatlaya+9,+Centro+Urbano,+54700+Cuautitl%C3%A1n+Izcalli&key=AIzaSyCFjEGZYmeX0OtTqAwuYtK4TRpqMmqvtVg"
          />
          <p>Despacho 12-09, Plaza Esmeralda</p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;

export default Home;
